@import '@/_styles/import';
// $width-xss: 327px;
$width-md: 688px;
$width-lg: 912px;
$width-xl: 1040px;
$width-xxl: 1439px;
$width-wide-start: $width-md;

@mixin layoutWide($from-width: $width-wide-start) {
  @media (min-width: $from-width) {
    @content;
  }
}

.root {
  --featurette-aspect-ratio: 5 / 3;
  --featurette-content-offset: unset;
  --featurette-content-v-space: 8px;
  --featurette-content-overlay-bg: transparent;
  --featurette-content-edge-width: 6px;
  --featurette-content-min-height: 139px;
  --featurette-content-padding: 20px;
  --featurette-content-width: unset;
  --featurette-cta-offset: 15px;
  --featurette-pause-offset: 18px;
  --featurette-textual-gap: 8px;

  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: 100%;

  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba($black-deep, 0.1);

  transition: background-color 0.7s ease;

  &:not(:has(.video, .picture)) {
    background-color: $lightgrey2;
  }

  @include layoutWide() {
    --featurette-content-offset: calc(10.75% - 35px);
    --featurette-aspect-ratio: 5 / 2;
    --featurette-content-min-height: calc(14.25% + 89px);
    --featurette-content-width: calc(13% + 143px);
    --featurette-border-radius-a: 5px;
    --featurette-border-radius-b: 0.8%;

    flex-direction: row;
    align-items: center;
    border-radius: calc(var(--featurette-border-radius-a) + var(--featurette-border-radius-b)) /
      calc(var(--featurette-border-radius-a) + calc(var(--featurette-aspect-ratio)) * var(--featurette-border-radius-b));
    box-shadow: none;

    &::before,
    &::after {
      $partition: 20;

      aspect-ratio: calc(var(--featurette-aspect-ratio)) / #{$partition};
      width: calc(100% / $partition);
    }

    &:global(._align-left) {
      justify-content: flex-start;

      &::after {
        content: '';
      }
    }

    &:global(._align-right) {
      justify-content: flex-end;

      &::before {
        content: '';
      }
    }
  }

  @include layoutWide($width-xxl) {
    --featurette-pause-offset: 24px;
    --featurette-textual-gap: 12px;
  }

  .controlIcon {
    width: 32px;
    height: 32px;
  }

  .controlIconWrapper {
    width: 32px;
    height: 32px;
    padding: 0;

    border: 0;
    border-radius: 3px;

    &:focus-visible {
      @include focusOutline();
    }
  }

  &:global(._theme-lightMode) {
    --featurette-content-color: #{$white};
    --featurette-content-overlay-bg: #{$gray-light};

    @include layoutWide() {
      --featurette-content-overlay-bg: #{rgba($black, 0.56)};
    }
  }

  &:global(._theme-darkMode) {
    --featurette-content-color: #{$black};
    --featurette-content-overlay-bg: #{$lightwhite};

    @include layoutWide() {
      --featurette-content-overlay-bg: #{rgba($white, 0.56)};
    }
  }

  &:global(._overlay-virginRedLight) {
    --featurette-content-edge-color: #{$vv-red};
  }

  &:global(._overlay-virginRedDark) {
    --featurette-content-edge-color: #{$vv-red};
  }

  &:global(._overlay-rockstarLight) {
    --featurette-content-edge-color: #{$brass-cabin};
  }

  &:global(._overlay-rockstarDark) {
    --featurette-content-edge-color: #{$brass-cabin};
  }

  &:global(._overlay-upliftLight) {
    --featurette-content-edge-color: #{$uplift};
  }

  &:global(._overlay-upliftDark) {
    --featurette-content-edge-color: #{$uplift};
  }

  &:global(._overlay-squidInkLight) {
    --featurette-content-edge-color: #{$purple};
  }

  &:global(._overlay-squidInkDark) {
    --featurette-content-edge-color: #{$purple};
  }

  &:global(._overlay-oceanBlueLight) {
    --featurette-content-edge-color: #{$blue-purple};
  }

  &:global(._overlay-oceanBlueDark) {
    --featurette-content-edge-color: #{$blue-purple};
  }

  &:global(._appearance-standard) {
    @include layoutWide() {
      --featurette-content-edge-width: unset;
      --featurette-content-padding: unset;
    }
  }

  &:global(._appearance-adaLockup) {
    @include layoutWide() {
      --featurette-content-padding: calc(1.85% + 3.33px);
    }

    @include layoutWide(0.97 * $width-lg) {
      --featurette-content-edge-width: 7px;
    }

    @include layoutWide(0.97 * $width-xxl) {
      --featurette-content-edge-width: 8px;
    }

    &:global(._theme-lightMode) {
      --promobanner-cta-arrow-color: #{$white};
    }
  }
}

.video,
.root > .picture {
  position: relative;

  @include layoutWide() {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
  }
}

.video {
  cursor: pointer;
  width: 100%;

  &:global(._playing) {
    cursor: default;
  }
}

.video video,
.picture {
  display: block;

  aspect-ratio: var(--featurette-aspect-ratio);
  width: 100%;

  object-fit: cover;
  object-position: 50% 50%;

  @include layoutWide() {
    aspect-ratio: unset;
    height: 100%;
  }
}

@mixin controlIcon() {
  cursor: pointer;

  position: absolute;
  z-index: 3;
  bottom: var(--featurette-pause-offset);

  display: none;

  .root:global(._align-left) & {
    right: var(--featurette-pause-offset);
  }

  .root:global(._align-right) & {
    right: var(--featurette-pause-offset);

    @include layoutWide() {
      right: unset;
      left: var(--featurette-pause-offset);
    }
  }
}

.pause {
  @include controlIcon();

  .video:global(._playing) & {
    display: block;
  }
}

.play {
  @include controlIcon();

  .video:global(:not(._playing)) & {
    display: block;
  }
}

.content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  min-height: calc(var(--featurette-content-min-height) + 2 * var(--featurette-content-padding));
  padding: var(--featurette-content-padding);

  color: var(--featurette-content-color);

  background-color: var(--featurette-content-overlay-bg);
  border-top: var(--featurette-content-edge-width) solid var(--featurette-content-edge-color);

  .root:global(._appearance-standard) & {
    border-top: 0;

    @include layoutWide() {
      width: var(--featurette-content-width);
      min-height: var(--featurette-content-min-height);
      background-color: transparent;
    }
  }

  .root:global(._appearance-adaLockup) & {
    @include layoutWide() {
      position: relative;

      width: calc(var(--featurette-content-width) + 2 * var(--featurette-content-padding));
      max-height: calc(100% - 2 * var(--featurette-content-edge-width));
      padding: calc(var(--featurette-content-padding) + var(--featurette-content-edge-width));

      background-color: transparent;
      border: 0;

      &::before {
        content: '';

        position: absolute;
        z-index: -1;
        inset: 0 var(--featurette-content-edge-width) var(--featurette-content-edge-width) 0;

        background-color: var(--featurette-content-overlay-bg);
      }

      &::after {
        content: '';

        position: absolute;
        z-index: -1;
        inset: var(--featurette-content-edge-width) 0 0 var(--featurette-content-edge-width);

        border: solid var(--featurette-content-edge-color);
        border-width: 0 var(--featurette-content-edge-width) var(--featurette-content-edge-width) 0;
      }
    }
  }

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include layoutWide() {
    z-index: 2;
    margin: var(--featurette-content-v-space) 0;

    .root:global(._align-left) & {
      margin: var(--featurette-content-v-space) 0 var(--featurette-content-v-space) var(--featurette-content-offset);
    }

    .root:global(._align-right) & {
      margin: var(--featurette-content-v-space) var(--featurette-content-offset) var(--featurette-content-v-space) 0;
    }
  }
}

.eyebrow {
  position: absolute;
  top: 14px;
  left: 20px;
  flex: 0 0 auto;

  @include layoutWide() {
    position: static;
    top: unset;
    left: unset;
    margin: 0 0 var(--featurette-textual-gap);
  }
}

.textual {
  overflow: hidden auto;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  row-gap: var(--featurette-textual-gap);

  @include scrollbar();
}

.title {
  margin: 0;

  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: -0.5px;

  @include layoutWide() {
    font-size: 16px;
    line-height: 20.8px;
    letter-spacing: -0.5px;
  }

  @include layoutWide($width-lg) {
    font-size: 21px;
    line-height: 27.3px;
    letter-spacing: -0.74px;
  }

  @include layoutWide($width-xl) {
    font-size: 25px;
    line-height: 31px;
    letter-spacing: -0.74px;
  }

  @include layoutWide($width-xxl) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -1px;
  }
}

.description {
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;

  @include layoutWide() {
    font-size: 13px;
    line-height: 16.9px;
  }

  @include layoutWide($width-xl) {
    font-size: 15px;
    line-height: 21px;
  }

  @include layoutWide($width-xxl) {
    font-size: 17px;
    line-height: 24px;
  }
}

.cta {
  flex: 0 0 auto;
  margin: var(--featurette-cta-offset) 0 0;
}
