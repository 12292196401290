@import '@/_styles/import';
@import './helpers/layoutWide';

$min-height: 160px;
$picture-vframe-mobile-offset: 5.75%;
$picture-vframe-mobile-shift: 5.75%;
$picture-vframe-offset: 8.25%;
$picture-vframe-shift: 8.25%;
$picture-porthole-mobile-offset: 10%;
$picture-porthole-mobile-shift: 10%;
$picture-porthole-offset: 12%;
$picture-porthole-shift: 10%;

.root {
  --leaderboard-content-width: 74.13%;
  --leaderboard-content-offset: 24px;
  --leaderboard-gap-content: 15px;
  --leaderboard-gap-textual: 8px;

  position: relative;

  overflow: hidden;

  width: 100%;
  height: 100%;
  min-height: $min-height;

  border-radius: 5px;

  transition: background-color 0.7s ease;

  &:not(:has(.picture)) {
    background-color: $lightgrey2;
  }

  @include layoutWide(75vw) {
    border-radius: 0;
  }

  @include layoutWide() {
    --leaderboard-content-width: 50.72%;
    --leaderboard-content-offset: 5.22%;
    --leaderboard-gap-content: 10px;
    --leaderboard-gap-textual: 2px;

    border-radius: 5px;
  }

  @include layoutWide($width-md) {
    --leaderboard-content-width: 58.77%;
    --leaderboard-content-offset: 5.92%;
    --leaderboard-gap-content: 14px;

    border-radius: 6px;
  }

  @include layoutWide($width-lg) {
    --leaderboard-content-width: 66.35%;
    --leaderboard-content-offset: 5.48%;
    --leaderboard-gap-content: 16px;
    --leaderboard-gap-textual: 4px;

    border-radius: 7px;
  }

  @include layoutWide($width-xl) {
    --leaderboard-content-width: 57.79%;
    --leaderboard-content-offset: 7.54%;

    border-radius: 8px;
  }

  &:global(._theme-lightMode) {
    --leaderboard-color-text: #{$white};
    --leaderboard-color-content-bg: #{$gray-light};
    --promobanner-cta-arrow-color: #{$white};
  }

  &:global(._theme-darkMode) {
    --leaderboard-color-text: #{$black};
    --leaderboard-color-content-bg: #{$lightwhite};
  }

  &:global(._overlay-oceanBlue) {
    --leaderboard-color-cover-circle: #{$blue-purple-dark};
    --leaderboard-color-cover-side: #{$blue-purple};
    --leaderboard-color-cover-bar: #{$blue-purple};
  }

  &:global(._overlay-rockstar) {
    --leaderboard-color-cover-circle: #{$brass-cabin-dark};
    --leaderboard-color-cover-side: #{$brass-cabin};
    --leaderboard-color-cover-bar: #{$brass-cabin};
  }

  &:global(._overlay-rockstarDark) {
    --leaderboard-color-cover-circle: #{$brass-cabin-dark};
    --leaderboard-color-cover-side: #{$black};
    --leaderboard-color-cover-bar: #{$brass-cabin};
  }

  &:global(._overlay-squidInk) {
    --leaderboard-color-cover-circle: #{$purple-dark};
    --leaderboard-color-cover-side: #{$purple};
    --leaderboard-color-cover-bar: #{$purple};
  }

  &:global(._overlay-uplift) {
    --leaderboard-color-cover-circle: #{$uplift-dark};
    --leaderboard-color-cover-side: #{$uplift};
    --leaderboard-color-cover-bar: #{$uplift};
  }

  &:global(._overlay-virginRed) {
    --leaderboard-color-cover-circle: #{$red-dark};
    --leaderboard-color-cover-side: #{$vv-red};
    --leaderboard-color-cover-bar: #{$vv-red};
  }

  &:global(._overlay-virginRedDark) {
    --leaderboard-color-cover-circle: #{$red-dark};
    --leaderboard-color-cover-side: #{$black};
    --leaderboard-color-cover-bar: #{$vv-red};
  }

  &:global(._text-left) {
    --leaderboard-text-align: left;
  }

  &:global(._text-center) {
    --leaderboard-text-align: center;
  }

  &:global(._text-right) {
    --leaderboard-text-align: right;
  }
}

.picture {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;

  min-height: $min-height;

  object-fit: cover;
  object-position: 50% 50%;

  :global(._appearance-standard) > & {
    width: 100%;
  }

  :global(._appearance-vFrame) > & {
    width: calc(100% - #{2 * $picture-vframe-mobile-offset});
  }

  :global(._appearance-vFrame._align-left) > & {
    right: #{$picture-vframe-mobile-offset - $picture-vframe-mobile-shift};
    left: #{$picture-vframe-mobile-offset + $picture-vframe-mobile-shift};
  }

  :global(._appearance-vFrame._align-center) > & {
    right: #{$picture-vframe-mobile-offset};
    left: #{$picture-vframe-mobile-offset};
  }

  :global(._appearance-vFrame._align-right) > & {
    right: #{$picture-vframe-mobile-offset + $picture-vframe-mobile-shift};
    left: #{$picture-vframe-mobile-offset - $picture-vframe-mobile-shift};
  }

  :global(._appearance-portholeFrame) > & {
    width: calc(100% - #{2 * $picture-porthole-mobile-offset});
  }

  :global(._appearance-portholeFrame._align-left) > & {
    right: #{$picture-porthole-mobile-offset - $picture-porthole-mobile-shift};
    left: #{$picture-porthole-mobile-offset + $picture-porthole-mobile-shift};
  }

  :global(._appearance-portholeFrame._align-center) > & {
    right: #{$picture-porthole-mobile-offset};
    left: #{$picture-porthole-mobile-offset};
  }

  :global(._appearance-portholeFrame._align-right) > & {
    right: #{$picture-porthole-mobile-offset + $picture-porthole-mobile-shift};
    left: #{$picture-porthole-mobile-offset - $picture-porthole-mobile-shift};
  }

  @media screen and (max-width: $container-sm-max-width) {
    :global(._appearance-vFrame._align-center) > & {
      right: 0;
      left: 0;
      width: 100%;
    }
  }

  @include layoutWide() {
    :global(._appearance-vFrame) > & {
      width: calc(100% - #{2 * $picture-vframe-offset});
    }

    :global(._appearance-vFrame._align-left) > & {
      right: #{$picture-vframe-offset - $picture-vframe-shift};
      left: #{$picture-vframe-offset + $picture-vframe-shift};
    }

    :global(._appearance-vFrame._align-center) > & {
      right: #{$picture-vframe-offset};
      left: #{$picture-vframe-offset};
    }

    :global(._appearance-vFrame._align-right) > & {
      right: #{$picture-vframe-offset + $picture-vframe-shift};
      left: #{$picture-vframe-offset - $picture-vframe-shift};
    }

    :global(._appearance-portholeFrame) > & {
      width: calc(100% - #{2 * $picture-porthole-offset});
    }

    :global(._appearance-portholeFrame._align-left) > & {
      right: #{$picture-porthole-offset - $picture-porthole-shift};
      left: #{$picture-porthole-offset + $picture-porthole-shift};
    }

    :global(._appearance-portholeFrame._align-center) > & {
      right: #{$picture-porthole-offset};
      left: #{$picture-porthole-offset};
    }

    :global(._appearance-portholeFrame._align-right) > & {
      right: #{$picture-porthole-offset + $picture-porthole-shift};
      left: #{$picture-porthole-offset - $picture-porthole-shift};
    }
  }
}

.cover {
  pointer-events: none;

  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;

  height: 100%;
  min-height: $min-height;
}

.content {
  position: relative;
  z-index: 3;

  display: flex;
  flex-direction: column;
  row-gap: var(--leaderboard-gap-content);
  justify-content: center;

  width: 100%;

  color: var(--leaderboard-color-text);

  :global(._align-left) > & {
    --leaderboard-content-padding: var(--leaderboard-gap-content)
      calc(100% - var(--leaderboard-content-width) - var(--leaderboard-content-offset)) var(--leaderboard-gap-content)
      var(--leaderboard-content-offset);
  }

  :global(._align-center) > & {
    --leaderboard-content-padding: var(--leaderboard-gap-content) calc(50% - var(--leaderboard-content-width) / 2);
  }

  :global(._align-right) > & {
    --leaderboard-content-padding: var(--leaderboard-gap-content) var(--leaderboard-content-offset)
      var(--leaderboard-gap-content) calc(100% - var(--leaderboard-content-width) - var(--leaderboard-content-offset));
  }

  &:global(._full) {
    margin: $min-height 0 0;
    padding: var(--leaderboard-content-offset);
    background-color: var(--leaderboard-color-content-bg);

    @include layoutWide() {
      min-height: $min-height;
      margin: 0;
      padding: var(--leaderboard-content-padding);
      background-color: transparent;
    }
  }

  &:global(:not(._full)) {
    min-height: $min-height;
    padding: var(--leaderboard-content-padding);
  }
}

.textual {
  overflow: visible;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  row-gap: var(--leaderboard-gap-textual);

  text-align: var(--leaderboard-text-align);

  @include scrollbar();
}

.title {
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;

  &:global(._full) > div > & {
    font-size: rem(20);
    line-height: rem(26);
    letter-spacing: rem(-0.56);
  }

  &:global(:not(._full)) > div > & {
    font-size: rem(17);
    line-height: rem(22.1);
    letter-spacing: rem(-0.32);
  }

  @include layoutWide() {
    font-size: rem(16);
    line-height: rem(20.8);
    letter-spacing: rem(-0.65);
  }

  @include layoutWide($width-md) {
    font-size: rem(20);
    line-height: rem(24);
    letter-spacing: rem(-1);
  }

  @include layoutWide($width-lg) {
    font-size: rem(24);
    line-height: rem(28);
    letter-spacing: rem(-1);
  }
}

.description {
  font-size: rem(14);
  font-weight: 500;
  line-height: rem(18.2);
  text-transform: capitalize;

  @include layoutWide() {
    font-size: rem(13);
    line-height: rem(16.9);
  }

  @include layoutWide($width-md) {
    font-size: rem(17);
    line-height: rem(24);
  }
}

.cta {
  flex: 0 0 auto;

  :global(._text-left) > div > & {
    align-self: flex-start;
  }

  :global(._text-center) > div > & {
    align-self: center;
  }

  :global(._text-right) > div > & {
    align-self: flex-end;
  }
}
